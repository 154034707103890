import React, { lazy, useState, Suspense } from "react";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import "./App.css";
import LoadingComponent from "./components/LoadingComponent";
import LogoHeader from "./assets/image/logo.svg";
// eslint-disable-next-line flowtype/require-valid-file-annotation
/* @flow */

const HeaderComponent = lazy(() =>
  import("./components/header/HeaderComponent")
);
const FooterComponent = lazy(() =>
  import("./components/footer/FooterComponent")
);

function App() {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const handleShow = () => {
    setShow(!show);
  };

  return (
    <div className="app">
      <div className="container-fluid p-0 m-0 vw-100 overflow-hidden">
        <Suspense fallback={LoadingComponent()}>
          <HeaderComponent navigate={navigate} handleShow={handleShow} />
        </Suspense>
        <div className="container vh-auto p-4 overflow-hidden">
          <Outlet />
        </div>
        <Suspense fallback={LoadingComponent()}>
          <FooterComponent />
        </Suspense>
      </div>
      <div
        className={`overlay-shadow ${show && "active"}`}
        style={{ width: !show ? "0%" : "100%", zIndex: 0 }}
      >
        <div className="overlay" style={{ width: !show ? "0%" : "100%" }}>
          <button className="btn closebtn" onClick={handleShow}>
            <i className="bi bi-x-lg text-white fs-2"></i>
          </button>
          <div className="col-12 overlay-content">
            <NavLink to="/" onClick={handleShow}>
              Inicio
            </NavLink>
            <NavLink to="/facturacion" onClick={handleShow}>
              Auto-Facturación
            </NavLink>
          </div>
          <div className="overlay-bottom">
            <img
              className="img"
              src={LogoHeader}
              alt="Logo"
              width="100px"
              height="56px"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
