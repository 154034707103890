import axios from "axios";

const url = process.env.REACT_APP_API_URL;

export function getFormasPago() {
  const config = {
    url: `${url}Catalogos/FormasPago`,
    method: "get",
    redirect: "follow",
  };

  return axios(config);
}

export function getRegimenFiscal() {
  const config = {
    url: `${url}Catalogos/RegimenFiscal`,
    method: "get",
    redirect: "follow",
  };

  return axios(config);
}

export function getUsoCFDI() {
  const config = {
    url: `${url}Catalogos/UsoCFDI`,
    method: "get",
    redirect: "follow",
  };

  return axios(config);
}

export function getEstados() {
  const config = {
    url: `${url}Catalogos/Estados`,
    method: "get",
    redirect: "follow",
  };

  return axios(config);
}

export function getCiudades(idEstado = null) {
  if (idEstado) {
    const config = {
      url: `${url}Catalogos/Ciudades?idestado=${idEstado}`,
      method: "get",
      redirect: "follow",
    };

    return axios(config);
  }
}

export function getSucursales() {
  const config = {
    url: `${process.env.REACT_APP_DISCHARGE_URL}`,
    method: "post",
    data: JSON.stringify({ empresaId: process.env.REACT_APP_COMPANY_ID }),
    dataType: "json",
    headers: { "Content-Type": "application/json" },
  };
  return axios(config);
}
